.infoButtonPanel .es-button__display {
  text-align: left !important;
}
/* TODO refactor these to be styled components */
.nestedrow-no-indent [class^="col"] {
  float: left;
  margin: 0.5rem 4% 0.5rem 0;
  min-height: 0.125rem;
}

.nestedrow-no-indent::after {
  display: table;
  clear: both;
}
