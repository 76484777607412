:root {
  --wtwLightBlue: #c8d7df;
  --wtwBlack: #000;
  --wtwWhite: #fff;
  --vb-software-gray-6: #979797;
  --cpBackgroundBeige: #EFEEDE;
   --wtwViolet: #702082;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: var(--cpBackgroundBeige);
}

#app {
  display: flex;
  flex-direction: column;
}

.es-tooltip__target{
  border-bottom: 0 none;
  cursor: auto;
}
